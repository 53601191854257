import { gantt } from 'dhtmlx-gantt'
import { chatCountKey } from '../chat/chat_count'


// dhtmlx-ganttのレイアウト設定
export const configureGantt = () => {

  // エラー表示OFF
  gantt.config.show_errors = false

  // 日付ヘッダのサイズ
  gantt.config.scale_height = 60

  // ガントチャートのサイズ
  gantt.config.row_height = 25
  gantt.config.task_height = 14
  gantt.config.min_column_width = 40

  // 進捗入力 OFF
  gantt.config.drag_progress = false

  // d&dによるタスク並び替え
  gantt.config.order_branch = true

  // 初期化時に一番初めのタスク位置に自動スクロール
  gantt.config.initial_scroll = false

  // タスク選択時の自動スクロール
  gantt.config.scroll_on_click = false

  // タスクのduration変更に応じて、ガントチャートの表示日数を自動調整
  gantt.config.fit_tasks = false


  _allLayout()
  _leftGridLayout()
  _barStyle()
  _locale()
}


// 全体: レイアウト
// https://docs.dhtmlx.com/gantt/desktop__layout_config.html#defaultlayout
const _allLayout = () => {

  gantt.config.layout = {
    css: 'gantt_container',
    rows: [
      {
        cols: [
          {
            view: 'grid',
            scrollX: 'scrollHor',
            scrollY: 'scrollVer'
          }, {
            resizer: false,
            width: 1
          }, {
            view: 'timeline',
            scrollX: 'scrollHor',
            scrollY: 'scrollVer'
          }, {
            view: 'scrollbar',
            id: 'scrollVer'
          }
        ]
      }, {
        view: 'scrollbar',
        id: 'scrollHor'
      }
    ]
  }
}


// 左グリッド: カラムレイアウト
const _leftGridLayout = () => {

  gantt.config.columns = [
    {
      name: 'theme',
      label: '',
      width: 18,
      template: function (task) {
        if (task.root) {
          return `<div class="left-column-line theme_${task.color_theme}"></div>`
        }
        return ''
      }

    }, {
      name: 'text',
      label: ' ',
      width: 200,
      tree: true

    }, {
      name: 'chat',
      label: '',
      width: 90,
      template: function (task) {
        const key = chatCountKey(task)
        const count = task.chat_count | 0
        let s = `<div class='left-column-chat' id='cc-${key}' task_id='${task.id}'>${count}</div>`
        
        const settingIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><defs><style>.cls{fill:#cbcbcb;}</style></defs><g><path class="cls" d="M12,5.33A.39.39,0,0,0,11.58,5a1.35,1.35,0,0,1-1.25-.82,1.36,1.36,0,0,1,.34-1.49.35.35,0,0,0,0-.47,5.91,5.91,0,0,0-.93-.94.34.34,0,0,0-.46,0,1.39,1.39,0,0,1-1.5.33A1.34,1.34,0,0,1,7,.39.33.33,0,0,0,6.7,0,6.09,6.09,0,0,0,5.35,0a.34.34,0,0,0-.3.34,1.33,1.33,0,0,1-.82,1.28,1.4,1.4,0,0,1-1.49-.34.34.34,0,0,0-.45,0,6.16,6.16,0,0,0-1,1,.35.35,0,0,0,0,.47,1.34,1.34,0,0,1,.33,1.5A1.38,1.38,0,0,1,.39,5,.32.32,0,0,0,0,5.3,6.27,6.27,0,0,0,0,6.67.39.39,0,0,0,.43,7a1.33,1.33,0,0,1,1.24.82,1.34,1.34,0,0,1-.34,1.49.35.35,0,0,0,0,.47,6.36,6.36,0,0,0,.93.94.34.34,0,0,0,.46,0,1.39,1.39,0,0,1,1.5-.33A1.34,1.34,0,0,1,5,11.61.33.33,0,0,0,5.3,12,5.32,5.32,0,0,0,6,12a4.87,4.87,0,0,0,.66,0,.34.34,0,0,0,.3-.34,1.33,1.33,0,0,1,.82-1.28,1.4,1.4,0,0,1,1.49.34.34.34,0,0,0,.45,0,6.63,6.63,0,0,0,1-1,.35.35,0,0,0,0-.47A1.33,1.33,0,0,1,11.53,7h.08A.33.33,0,0,0,12,6.7,6.27,6.27,0,0,0,12,5.33ZM6.51,8A2,2,0,1,1,8,6.51,2,2,0,0,1,6.51,8Z"/></g></svg>'
        const plusIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><g><polygon points="10 4 6 4 6 0 4 0 4 4 0 4 0 6 4 6 4 10 6 10 6 6 10 6 10 4"/></g></svg>'

        if (task.root && task.is_owner == 1) {
          s += `<div class='left-column-config' task_id='${task.id}'><span>${settingIcon}</span></div>`
          s += `<div class='left-column-add-task theme_svg_${task.color_theme}' task_id='${task.id}'><span>${plusIcon}</span></div>`
        }

        return `<div class='left-column-tools'>${s}</div>`
      }
    }
  ]

  // 左グリッド: 標準アイコン非表示
  gantt.templates.grid_indent = (item) => {
    return ''
  }
  gantt.templates.grid_file = (item) => {
    return ''
  }
  gantt.templates.grid_folder = (item) => {
    return ''
  }

  // 左グリッド: タイトルのスタイル
  gantt.templates.grid_row_class = (start, end, task) => {
    if (task.type == gantt.config.types.project) {
      return 'left-column-project'
    }
    if (task.root == true) return 'project-row'
    return 'task-row'
  }

  // 左グリッド: アイコン画像用
  // gantt.templates.grid_open = function(item) {
  //   return '<div class='gantt_tree_icon gantt_' + (item.$open ? 'close' : 'open') + ''></div>'
  // }
}


const _barStyle = () => {

  // Project/Task: 行のスタイル
  gantt.templates.task_row_class = (start, end, task) => {
    if (task.root == true) return 'project-row'
    return 'task-row'
  }

  // Project: バーのスタイル
  gantt.config.type_renderers[gantt.config.types.project] = function (task) {
    var main_el = document.createElement('div')

    main_el.className = `custom-project theme_${task.color_theme}`
    main_el.setAttribute(gantt.config.task_attribute, task.id)
    main_el.innerHTML = `<div class='text'>${task.text}</div>`

    var size = gantt.getTaskPosition(task, task.planned_start, task.planned_end)
    main_el.style.top = `${size.top}px`
    main_el.style.left = `${size.left}px`
    main_el.style.width = `${size.width}px`
    main_el.style.height = '24px'
    return main_el
  }

  // Task: バーのスタイル
  gantt.templates.task_class = (start, end, task) => {
    if (task.root == true) {
      return `theme_${task.color_theme}`
    }
    // color_theme=1であればグレー
    if (task.color_theme == 1) {
      return 'theme_0'
    }
    // それ以外はプロジェクトの色を継承
    var parentTask = gantt.getTask(task.parent)
    return `theme_${parentTask.color_theme}`
  }

  // Task: マイルストーンのタイトルを右に表示
  gantt.templates.rightside_text = function (start, end, task) {
    if (task.type == gantt.config.types.milestone) {
      return task.text
    }
    return ''
  }
}


const _locale = () => {
  gantt.locale = {
    date: {
      month_full: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      month_short: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      day_full: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
      day_short: ['日', '月', '火', '水', '木', '金', '土']
    },
    labels: {
      dhx_cal_today_button: '今日',
      day_tab: '日',
      week_tab: '週',
      month_tab: '月',
      new_event: '新イベント',
      icon_save: '保存',
      icon_cancel: 'キャンセル',
      icon_details: '詳細',
      icon_edit: '編集',
      icon_delete: '削除',
      confirm_closing: '',
      confirm_deleting: 'イベント完全に削除されます、宜しいですか？',
      section_description: 'デスクリプション',
      section_time: '期間',
      section_type: 'Type',
      column_text: 'Task name',
      column_start_date: 'Start time',
      column_duration: 'Duration',
      column_add: '',
      link: '',
      confirm_link_deleting: 'の接続を削除しますか？',
      link_start: ' (start)',
      link_end: ' (end)',
      type_task: 'Task',
      type_project: 'Project',
      type_milestone: 'Milestone',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Week',
      months: 'Months',
      years: 'Years',
      message_ok: 'OK',
      message_cancel: 'キャンセル'
    }
  }
}
