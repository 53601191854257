import { log } from '../util/logger'
import { Fetcher } from '../util/fetcher'
import { renderProjects } from './project'
import { timeoutAsync, CancellablePromiseCancelled } from '../util/cancellable_promise'
import { showErrorAlert, ignoreError } from '../util/alert'


// 最後に操作が完了してからX秒後にAPIで保存
const syncTimeSec = 0
let syncTimer: any


export const configureProjectList = () => {
  _configureDOMEvents()
  loadProjectList()
}


// -------------------------------
// jQueryイベントの設定
// -------------------------------

const _configureDOMEvents = () => {

  // プロジェクトリストの開閉
  $('.home_nav_toggle').on('click', () => {
    $('.home_nav').toggleClass('isOpen')
    return false
  })
  
  // リスト開閉
  $('.wsttl_link').on('click', (event: any) => {
    $('#' + $(event.currentTarget).data('target')).slideToggle()
    $(event.currentTarget).toggleClass('list_close')
    return false
  })

  // チェックON/OFF
  $(document).on('click', 'li.home_nav_ws_proj', _checkStateChanged)
  
  // 並び替え
  $('.home_nav_ws_projs').sortable({
    cursor: "move",
    axis: "y",
    update: () => {
      _startSyncTimer()
      renderProjects()
    }
  })
}


// -------------------------------
// APIアクセス
// -------------------------------

export const loadProjectList = async () => {
  try {
    const response = await Fetcher('get', `/projects`, null, null)
    _renderList(response.data.data)

  } catch (error) {
    showErrorAlert(error)
  }
}

// チェックON/OFFで再描画
const _checkStateChanged = (event: any) => {
  $(event.currentTarget).toggleClass('select_proj')
  _startSyncTimer()
  renderProjects()
}

// 選択状態のプロジェクトを取得
export const filteredProjectIds = (): number[] => {

  //TODO フォーカスモードを実装する場合、ここでフォーカスするプロジェクトのIDのみ返せばOK

  const projectIds: number[] = []
  const selectedList = $('li.home_nav_ws_proj.select_proj')
  $.each(selectedList, (index, value) => {
    const id = $(selectedList[index]).data('id')
    projectIds.push(id)
  })
  return projectIds
}

const _cancelSyncTimer = () => {
  if (syncTimer === undefined) { return }
  syncTimer.cancel()
  syncTimer = undefined
}

const _startSyncTimer = async () => {
  _cancelSyncTimer()
  syncTimer = timeoutAsync(syncTimeSec)
  try {
    await syncTimer
    _submitState()

  } catch (e) {
    if (e instanceof CancellablePromiseCancelled) {
      log('cancel list sync')
    }
  }
}

const _submitState = async () => {
  const list: string[] = []
  $.each($('li.home_nav_ws_proj'), (index, value) => {
    const selected = $(value).hasClass('select_proj')
    const json = `{"id": ${$(value).data('id')}, "on": ${selected}}`
    list.push(json)
  })
  const ids = `[${list.join(',')}]`
  try {
    const response = await Fetcher('put', `/projects/sort`, null, { ids: ids })

  } catch (error) {
    showErrorAlert(error)
  }
}


// -------------------------------
// リスト書き換え
// -------------------------------

const _renderList = (data: any) => {
  $('#wksp_00 > ul').html('')
  $('#wksp_01 > ul').html('')

  $.each(data, (index, value) => {
    const selected = value.is_on ? 'select_proj' : ''
    let li = ''
    li += `<li class='home_nav_ws_proj theme_clr${value.color_theme} ${selected}' data-id='${value.id}'>`
    li +=   `<div class='home_nav_ws_proj_wrap'>`
    li +=     `<label class='home_nav_ws_proj_label'>${value.text}</label>`
    li +=   '</div>'
    li += '</li>'

    if (value.is_owner) {
      $('#wksp_00 > ul').append(li)
    } else {
      $('#wksp_01 > ul').append(li)
    }
  })
}