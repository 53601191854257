import { gantt } from 'dhtmlx-gantt'
import { showEditProjectModal } from './project'
import { showEditTaskModal } from './task'


export const configureModal = () => {
  // モーダル共通閉じる処理
  $('.mdl_cancel_btn, .js_home_mdl_close').on('click', hideModal)

  // dhtmlx-ganttのデフォルトLightBoxを上書き
  gantt.hideLightbox = () => {}
  gantt.showLightbox = (id) => {
    var task = gantt.getTask(id)

    if (task.type == gantt.config.types.project) {
      showEditProjectModal(Number(id))
    } else {
      showEditTaskModal(String(id))
    }
  }
}


// -------------------------------
// モーダル表示Util
// -------------------------------

let modalStack: string[] = []

export const showModal = (name: string) => {
  const modal = $(name)
  modal.show()
  modal.find('.home_modal_main').addClass('mdl_show')
  modalStack.push(name)
  return false
}

export const hideModal = () => {
  const currentStack = modalStack.pop()
  if (currentStack == undefined) { return false }

  const modal = $(currentStack)
  modal.find('.home_modal_main').removeClass('mdl_show')
  modal.hide()
  return false
}

export const hideAllModal = () => {
  $('.home_modal_main').removeClass('mdl_show')
  $('.home_modalContent').hide()
}

// 削除ダイアログ
export const showAlertModal = (title: string, message: string, onclick: () => void) => {
  const modalTitle = $('#delete_mdl .modal_home_ttl')
  modalTitle.css({ color: '#ff001f' })
  modalTitle.html(title)

  const modalBtn = $('#delete_mdl .mdl_delete_btn')
  modalBtn.css({ backgroundColor: '#ff001f' })
  modalBtn.off('click').on('click', onclick)
  modalBtn.html('削除')

  $('.delete_modal_txt').html(`<span>${message}</span><br />この操作は取り消せません。`)
  showModal('#delete_mdl')
}

// アーカイブ実行ダイアログ
export const showArchiveModal = (title: string, message: string, onclick: () => void) => {
  const modalTitle = $('#delete_mdl .modal_home_ttl')
  modalTitle.css({ color: '#2286fb' })
  modalTitle.html(title)

  const modalBtn = $('#delete_mdl .mdl_delete_btn')
  modalBtn.css({ backgroundColor: '#2286fb' })
  modalBtn.off('click').on('click', onclick)
  modalBtn.html('アーカイブ')

  $('.delete_modal_txt').html(message)
  showModal('#delete_mdl')
}

// Freeはアーカイブ使えないダイアログ
export const showArchiveFreeModal = (onclick: () => void) => {
  const modalTitle = $('#delete_mdl .modal_home_ttl')
  modalTitle.css({ color: '#2286fb' })
  modalTitle.html('アーカイブできません')

  const modalBtn = $('#delete_mdl .mdl_delete_btn')
  modalBtn.css({ backgroundColor: '#2286fb' })
  modalBtn.off('click').on('click', onclick)
  modalBtn.html('料金プラン')

  $('.delete_modal_txt').html('Freeプランではアーカイブ機能を<br />ご利用いただけません。')
  showModal('#delete_mdl')
}

// Freeは社外メンバー招待出来ないダイアログ
export const showOtherMemberLockedModal = (onclick: () => void) => {
  const modalTitle = $('#delete_mdl .modal_home_ttl')
  modalTitle.css({ color: '#2286fb' })
  modalTitle.html('社外メンバーの招待')

  const modalBtn = $('#delete_mdl .mdl_delete_btn')
  modalBtn.css({ backgroundColor: '#2286fb' })
  modalBtn.off('click').on('click', onclick)
  modalBtn.html('料金プラン')

  $('.delete_modal_txt').html('Freeプランでは社外メンバーを招待出来ません。')
  showModal('#delete_mdl')
}

export const hideAlertModal = () => {
  $('#delete_mdl').hide()
}
