import { showModal } from './modal'

export const showStartGuide = () => {
  if ($('#startguide').length == 0) { return }

  $('.startguide_menu_list li').on('click', (event: any) => {
    const Index = $(event.currentTarget).index()
    const setpname = $(event.currentTarget).children('a').attr('href')
    _detail(setpname, Index)
    return false
  })

  $('.startguide_setp_link .next_btn').on('click', (event: any) => {
    const Index = $(event.currentTarget).data('btnid')
    const setpname = $(event.currentTarget).attr('href')
    _detail(setpname, Index)
    return false
  })

  $('.startguide_setp_link .back_btn').on('click', (event: any) => {
    const Index = $(event.currentTarget).data('btnid')
    const setpname = $(event.currentTarget).attr('href')
    _detail(setpname, Index)
    return false
  })

  showModal('#startguide')
}

const _detail = (setpname, btnid) => {
  $('.startguide_menu_list li a').removeClass('active')
  $('.startguide_menu_list li').eq(btnid).children('a').addClass('active')
  const SetpObj = $(setpname)
  $('.startguide_detail_list li').removeClass('detail_active')
  SetpObj.addClass('detail_active')
}
