import { gantt } from 'dhtmlx-gantt'
import { log } from '../util/logger'
import { Fetcher } from '../util/fetcher'
import { timeoutAsync, CancellablePromiseCancelled } from '../util/cancellable_promise'
import { shiftMilestone, updateDateRange } from './util'
import { clearDataCache } from './project'
import { showErrorAlert, ignoreError } from '../util/alert'


// 最後に並べ替えが完了してからX秒後にAPIで保存
const syncTimeSec = 0
let syncTimer: any
let modifiedTaskIds: string[] = []


export const configureTaskUpdate = () => {

  gantt.attachEvent("onAfterTaskDrag", function (id, mode, e) {
    shiftMilestone(id)
    updateDateRange()
  }, null)


  // チャート上で直接日付操作された場合の変更
  gantt.attachEvent("onAfterTaskUpdate", (id, task) => {
    if (task.type == gantt.config.types.project) { return }

    // 初回Render時にマイルストーンの描画位置をずらすと実行されてしまうのでブロック
    if (task.shifted) {
      task.shifted = false
      return
    }
    
    if (!modifiedTaskIds.includes(id)) {
      modifiedTaskIds.push(id)
    }
    _startSyncTimer()
  }, null)
}

const _cancelSyncTimer = () => {
  if (syncTimer === undefined) { return }
  syncTimer.cancel()
  syncTimer = undefined
}

const _startSyncTimer = async () => {
  _cancelSyncTimer()
  syncTimer = timeoutAsync(syncTimeSec)
  try {
    await syncTimer
    _submitChanges()

  } catch (e) {
    if (e instanceof CancellablePromiseCancelled) {
      log('cancel task mod sync')
    }
  }
}

const _submitChanges = async () => {
  let json: string[] = []

  if (modifiedTaskIds.length == 0) {
    return
  }

  modifiedTaskIds.forEach(id => {
    const task = gantt.getTask(id)
    const tid = Number(task.id.replace('task-', ''))

    // 終了日時を操作
    const start = task.start_date
    const end = new Date(task.end_date.getTime() - 1)
    task.end_date = end
    gantt.updateTask(id, task)
    
    json.push(`{"id": ${tid}, "start": "${_formatDate(start)}", "end": "${_formatDate(end)}" }`)
  })

  modifiedTaskIds = []
  const param = `[${json.join(',')}]`

  try {
    const response = await Fetcher('put', `/task_batch_update`, null, { json: param })
    clearDataCache()
    
  } catch (error) {
    showErrorAlert(error)
  }
}

const _formatDate = (date: Date): string => {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()
  return `${y}/${m}/${d}`
}