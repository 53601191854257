import { log } from '../util/logger'
import { gantt } from 'dhtmlx-gantt'
import { closeChatWindow } from '../chat/chat'
import { timeoutAsync, CancellablePromiseCancelled } from '../util/cancellable_promise'
import { Fetcher } from '../util/fetcher'
import { showErrorAlert, ignoreError } from '../util/alert'


// 最後に並べ替えが完了してからX秒後にAPIで保存
const syncTimeSec = 0
let syncTimer: any

let modifiedProjectIds: number[] = []


export const configureTaskSort = () => {

  // Owner権限の無いプロジェクトのタスク修正は不可
  gantt.attachEvent("onBeforeTaskDrag", (id, mode, e) => {
    return gantt.getTask(gantt.getTask(id).parent).is_owner == 1
  }, null)

  // 違うプロジェクトへの並び替えは不可
  gantt.attachEvent("onBeforeTaskMove", (id, parent, tindex) => {
    closeChatWindow()
    return gantt.getTask(id).parent == parent
  }, null)

  // Owner権限のあるプロジェクトのタスクのみ並び替えが可能
  gantt.attachEvent("onRowDragStart", function (id, target, e) {
    const task = gantt.getTask(id)
    if (task.type == gantt.config.types.project) { return }

    const is_owner = gantt.getTask(task.parent).is_owner == 1
    if (is_owner) {
      _cancelSyncTimer()
    }
    return is_owner
  }, null)

  // 並び替え: 変更があったparentIdを記録  
  gantt.attachEvent("onRowDragEnd", function (id, target) {
    const task = gantt.getTask(id)
    const parent = gantt.getTask(task.parent)
    
    if (!modifiedProjectIds.includes(parent.id)) {
      modifiedProjectIds.push(parent.id)      
    }
    _startSyncTimer()
    return true
  }, null)
}

const _cancelSyncTimer = () => {
  if (syncTimer === undefined) { return }
  syncTimer.cancel()
  syncTimer = undefined
}

const _startSyncTimer = async () => {
  syncTimer = timeoutAsync(syncTimeSec)
  try {
    await syncTimer
    _submitTaskOrder()

  } catch (e) {
    if (e instanceof CancellablePromiseCancelled) {
      log('cancel order sync')
    }
  }
}

const _submitTaskOrder = async () => {
  if (modifiedProjectIds.length == 0) { return }

  // 変更があったプロジェクトの全タスクをリストアップ
  let order: number[] = []
  gantt.eachTask((task) => {
    if (modifiedProjectIds.includes(task.parent)) {
      order.push(Number(task.id.replace('task-', '')))
    }
  })  
  modifiedProjectIds = []
  if (order.length == 0) { return }
  // log(order)
  
  try {
    const response = await Fetcher('put', `/task_batch_sort`, null, { ids: order.join(',') })

  } catch (error) {
    showErrorAlert(error)
  }
}