import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { log } from '../util/logger'
import { Fetcher } from '../util/fetcher'
import { showErrorAlert, ignoreError } from '../util/alert'
import { timeAgoInWords } from '../util/date'


const Notice = (props: Props) => {

  useEffect(() => { }, [])

  const _renderNotice = (notice: any) => {
    const alreadyReadClass = notice.is_read ? 'already_read' : ''

    return (
      <li key={notice.id} className="message" >
        <a href={`/news/${notice.news_id}`} className={alreadyReadClass} onClick={() => _onClickMessage(notice)}>
          <div>
            <p className="title" dangerouslySetInnerHTML={{ __html: notice.title }} />
            <p className="content" dangerouslySetInnerHTML={{ __html: notice.body }} />
            <p className="minesago">{timeAgoInWords(notice.created_at)}</p>
          </div>
        </a>
      </li>
    )
  }

  //**************************
  // event
  //**************************

  const _onClickMessage = (notice: any) => {
    if (!notice.is_read) updateNoticeAlredyRead(notice.id)
  }

  const _onScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && props.hasNext) {
      const nextPage = props.page + 1
      updateNotice(nextPage, props.data)
    }
  }

  //**************************
  // return
  //**************************

  const count = props.data.length
  return (
    <div className="popover popover_messages" onScroll={_onScroll}>
      <div className="arrow"></div>
      <div className="popover_header_wrap">
        <h3 className="popover-header">お知らせ</h3>
        {props.unreadCount > 0 && <a className="popover-header-asreadall" href="#" onClick={_updateNoticeAllAlredyRead}> すべて既読にする</a>}
      </div>
      <div className="popover-body">
        <ul className="messages">
          {count > 0 && props.data.map(d => _renderNotice(d))}
          {count == 0 && <li className="zero-message">お知らせはありません</li>}
        </ul>
      </div>
    </div>
  )
}

type Props = {
  data: any[]
  hasNext: boolean
  unreadCount: number
  page: number
}

Notice.propTypes = {
  data: PropTypes.array,
  hasNext: PropTypes.bool,
  unreadCount: PropTypes.number,
  page: PropTypes.number,
}

//**************************
// loadData
//**************************

let noticeCurrentData
let noticeUnreadCount
let noticePage
let noticeHasNext

export const updateNotice = async (page: number = 1, data: any[] = []) => {
  try {
    const response = await Fetcher('get', `/news_notifications`, { 'page': page }, null)

    noticeCurrentData = [...data, ...response.data.news_notifications]
    noticeUnreadCount = response.data.unread_count
    noticePage = page
    noticeHasNext = response.data.has_next

    renderNotice()
    updateUnreadCount(noticeUnreadCount)
  } catch (error) {
    showErrorAlert(error)
  }
}

const _updateNoticeAllAlredyRead = async (e: any) => {
  e.preventDfault
  try {
    await Fetcher('put', `/news_notifications`, null, null)
    updateNotice()
  } catch (error) {
    showErrorAlert(error)
  }
}

const updateNoticeAlredyRead = async (noticeId: number) => {
  try {
    await Fetcher('put', `/news_notifications/${noticeId}`, null, null)
    //アイコンの数値更新
    noticeUnreadCount = noticeUnreadCount - 1
    updateUnreadCount(noticeUnreadCount)

    //既読に更新
    let notice = noticeCurrentData.find(n => n.id == noticeId)
    notice.is_read = true
    renderNotice()

  } catch (error) {
    showErrorAlert(error)
  }
}


const updateUnreadCount = (unreadCount: number) => {
  if (unreadCount > 0) {
    $('#noticeCountValue').text(unreadCount)
    $('.icon_notice .icon_count').show()
  } else {
    $('#noticeCountValue').text(unreadCount)
    $('.icon_notice .icon_count').hide()
  }
}

const renderNotice = () => {
  const node = document.getElementById('tmpl_notice')
  ReactDOM.render(<Notice data={noticeCurrentData} hasNext={noticeHasNext} unreadCount={noticeUnreadCount} page={noticePage} />, node)
}