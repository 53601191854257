import { gantt } from 'dhtmlx-gantt'


export const configureCalendar = () => {
  $('.move_today').on('click', showToday)
  $('.grid_resize').on('click',  _updateScale)

  gantt.templates.scale_row_class = (scale) => {
    switch(scale.unit){
      case 'day':    return 'day_scale'
      case 'month':  return 'month_scale'
      default:       return 'week_scale'
    }
  }

  // 今日と週末のセルスタイル
  gantt.templates.timeline_cell_class = (task, date) => {
    return _scaleCellClass(date)
  }

  // 日付スケールの設定
  _updateScale()
}

// 今日に移動
export const showToday = () => {
  const date = new Date()
  date.setHours(0, 0, 0, 0)
  gantt.showDate(date)
}

// 日付スケールの変更
const _updateScale = () => {
  const range = $('.headerrange')

  const mod = (name: string) => {
    range.removeClass('range_wide range_middle range_tight')
    range.addClass(name)
    _saveCurrentScale(name)
  }

  // クラス切り替え
  if (range.hasClass('range_tight')) {
    mod('range_middle')
  } else if (range.hasClass('range_middle')) {
    mod('range_wide')
  } else if (range.hasClass('range_wide')) {
    mod('range_tight')
  } else {
    mod(_loadCurrentScale())
  }

  // スケールに反映
  if (range.hasClass('range_tight')) {
    _setScaleRangeDay()
  } else if (range.hasClass('range_middle')) {
    _setScaleRangeWeek()
  } else if (range.hasClass('range_wide')) {
    _setScaleRangeMonth()
  }
  gantt.render()
  return false
}

// cookieからロード
const _loadCurrentScale = (): string => {
  var cookies = document.cookie;
  var cookiesArray = cookies.split(';');

  for (var c of cookiesArray) {
    var cArray = c.split('=');
    if (cArray[0].trim() == 'scale_range') {
      return cArray[1];
    }
  }
  return 'range_tight'
}

// cookieへ保存
const _saveCurrentScale = (value: string) => {
  document.cookie = `scale_range=${value}`
}

const _setScaleRangeDay = () => {
  gantt.config.scales = [
    { unit: "month", step: 1, format: "%F" },
    { unit: "day", step: 1, format: _formatDate, css: _scaleCellClass }
  ]
  gantt.config.min_column_width = 40
}

const _setScaleRangeWeek = () => {
  gantt.config.scales = [
    { unit: "month", step: 1, format: "%F" },
    { unit: "day", step: 1, format: _formatShortDate, css: _scaleCellClass }
  ]
  gantt.config.min_column_width = 20
}

const _setScaleRangeMonth = () => {
  gantt.config.scales = [
    { unit: "month", step: 1, format: "%F" },
    { unit: "day", step: 1, format: _formatNone, css: _scaleCellClass }
  ]
  gantt.config.min_column_width = 10
}

const _formatDate = (date: Date): string => {
  const day = gantt.locale.date.day_short[date.getDay()]
  return `${date.getDate()}${day}`
}

const _formatShortDate = (date: Date): string => {
  return `${date.getDate()}`
}

const _formatNone = (date: Date): string => {
  return ''
}

// 今日と週末のセルスタイル
const _scaleCellClass = (date: Date): string => {
  const today = new Date()
  const date_to_str = gantt.date.date_to_str(gantt.config.task_date)

  let cls = ''
  if (date.getDay() == 0 || date.getDay() == 6) {
    cls = 'weekend '
  }
  if (date_to_str(date) === date_to_str(today)) {
    cls += 'today'
  }
  return cls
}
