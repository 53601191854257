import { pusherKey } from '../util/env'
import { updateMessage } from './message'
import { reloadChatIfNeeded } from './chat'

const pusher = (channel) => {
  // Pusher.logToConsole = true

  const pusher = new Pusher(pusherKey(), { cluster: 'ap3' })

  pusher.subscribe(channel).bind('chat-event', (data: any) => {
    updateMessage()
    reloadChatIfNeeded(data)
  })
}

export const configurePusher = () => {
  pusher($('#pusher_channel').val())
}