// https://qiita.com/sdkei/items/9ab9f8f1391da2e1aa27

/**
 * キャンセル可能なオブジェクトのインターフェイス。
 */
interface Cancellable {
  cancel(): void
}

/**
 * キャンセル可能な Promise。
 * 
 * キャンセルするためのメソッド cancel() を持つ。
 * cancel() を呼び出すと Promise が reject される。
 * このときの reason には Cancelled クラスのインスタンスが渡される。
 */
type CancellablePromise<T> = Promise<T> & Cancellable

/**
 * 指定されたオブジェクトに cancel メソッドを追加して Cancellable にする。
 * 
 * @param base Cancellable にするオブジェクト。
 * @param cancel cancel メソッドとして base のメンバに追加する関数。
 */
function toCancellable<T>(base: T, cancel: () => void): T & Cancellable {
  const baseAsAny = base as any
  baseAsAny.cancel = cancel
  return baseAsAny
}

 /**
  * CancellablePromise がキャンセルされたときに
  * reject の reason として渡されるオブジェクトのクラス。
  */
export class CancellablePromiseCancelled {}

/**
 * 指定された時間の後に resolve される、キャンセル可能な Promise を生成して返す。
 * 
 * @param delay 返した Promise が resolve されるまでの時間[ミリ秒]。
 */
export const timeoutAsync = (delay: number): CancellablePromise<void> => {
  // setTimeout 関数が返した ID。
  // 指定時間が経過するかキャンセルされたら null。
  let timeoutID: any//number | null = null
  let rejectPromise: (reason: CancellablePromiseCancelled) => void = (_) => {} // non-nullable 型にするため、ダミーの null オブジェクトで初期化する。

  const promise = new Promise<void>((resolve, reject) => {
    timeoutID = setTimeout(() => {
      timeoutID = null
      resolve()
    }, delay)
    rejectPromise = reject
  })

  const cancel = () => {
    if (timeoutID !== null) {
      clearTimeout(timeoutID)
      timeoutID = null
      rejectPromise(new CancellablePromiseCancelled())
    }
  }
  return toCancellable(promise, cancel)
}