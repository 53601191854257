import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { log } from '../util/logger'
import { Fetcher } from '../util/fetcher'
import { openChatWindow } from './chat'
import { gantt } from 'dhtmlx-gantt'
import { showErrorAlert, ignoreError } from '../util/alert'
import { timeAgoInWords } from '../util/date'


const Message = (props: Props) => {

  useEffect(() => { }, [])

  const _renderMessage = (message: any) => {
    const user = message.user
    const alreadyReadClass = message.is_read ? 'already_read' : ''

    return (
      <li key={message.id} className="message" onClick={() => { _onClickMessage(message) }} >
        <a href='#' className={alreadyReadClass}>
          <div className="pic"><img alt="" src={message.user.image} /></div>
          <div>
            <p className="member" dangerouslySetInnerHTML={{ __html: user.name }} />
            <p className="title" dangerouslySetInnerHTML={{ __html: message.title }} />
            <p className="content" dangerouslySetInnerHTML={{ __html: message.text }} />
            <p className="minesago">{timeAgoInWords(message.created_at)}</p>
          </div>
        </a>
      </li>
    )
  }

  //**************************
  // event
  //**************************

  const _onClickMessage = (message) => {
    const id = message.task_id ? `task-${message.task_id}` : message.project_id
    const task = gantt.getTask(id)
    if (task === undefined) {
      showErrorAlert('プロジェクトを表示してください。該当プロジェクトやタスクが非表示だったり、削除されていると表示出来ません。')
      return
    }
    openChatWindow(id)
  }

  const _onScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && props.hasNext) {
      const nextPage = props.page + 1
      updateMessage(nextPage, props.data)
    }
  }

  //**************************
  // api
  //**************************

  const _updateAllAlredyRead = async (e: any) => {
    e.preventDfault
    try {
      const response = await Fetcher('put', `/notifications/`, null, null)
      updateMessage()

    } catch (error) {
      showErrorAlert(error)
    }
  }

  const count = props.data.length
  return (
    <div className="popover popover_messages" onScroll={_onScroll}>
      <div className="arrow"></div>

      <div className="popover_header_wrap">
        <h3 className="popover-header">メッセージ</h3>
        {props.unreadCount > 0 && <a className="popover-header-asreadall" href="#" onClick={_updateAllAlredyRead}> すべて既読にする</a>}
      </div>

      <div className="popover-body">
        <ul className="messages">
          {count > 0 && props.data.map(m => _renderMessage(m))}
          {count == 0 && <li className="zero-message">新着メッセージはありません</li>}
        </ul>
      </div>
    </div>
  )
}

type Props = {
  data: any[]
  hasNext: boolean
  unreadCount: number
  page: number
}

Message.propTypes = {
  data: PropTypes.array,
  hasNext: PropTypes.bool,
  unreadCount: PropTypes.number,
  page: PropTypes.number
}


//**************************
// loadData
//**************************
let currentData;
let unreadCount;
let hasNext;
let page;

export const updateMessage = async (page: number = 1, data: any[] = []) => {
  try {
    const response = await Fetcher('get', `/notifications`, { 'page': page }, null)
    const newData = [...data, ...response.data.chat_notifications]
    currentData = newData;
    unreadCount = response.data.unread_count
    hasNext = response.data.has_next;
    page = page;
    renderMessage(newData, response.data.has_next, unreadCount, page)
    updateUnreadCount()
  } catch (error) {
    showErrorAlert(error)
  }
}

const updateUnreadCount = () => {
  if (unreadCount > 0) {
    $('#messageCountValue').text(unreadCount)
    $('.icon_message .icon_count').show()
  } else {
    $('#messageCountValue').text(unreadCount)
    $('.icon_message .icon_count').hide()
  }
}

export const updateMessageOnlyFront = async (projectId: number, taskId: number | null) => {
  const newData = currentData.map((data) => {
    if (data.project_id == projectId && data.task_id == taskId && !data.is_read) {
      data.is_read = 1;
      unreadCount--;
    }
    return data;
  })
  renderMessage(newData, hasNext, unreadCount, page)
  updateUnreadCount()
}


const renderMessage = (data: any[] = [], hasNext: boolean = false, unreadCount: number = 0, page: number = 1) => {
  const node = document.getElementById('tmpl_message')
  ReactDOM.render(<Message data={data} hasNext={hasNext} unreadCount={unreadCount} page={page} />, node)
}