import { gantt } from 'dhtmlx-gantt'
import React from 'react'
import ReactDOM from 'react-dom'
import { showErrorAlert, ignoreError } from '../util/alert'
import { ChatComponent } from './chat_component'


export const ChatElementName = 'react-chat'
let currentChatTaskId: any = undefined


export const configureChat = () => {
  $(document).on('click', '.left-column-chat', _openFromGridNumber)
  $('.chat_tool_btn').on('click', _openFromToolbar)
  gantt.attachEvent("onTaskSelected", _openFromGridSelection, null)
}


// 左グリッド: チャット数字クリック
const _openFromGridNumber = (event: any) => {
  const taskId = $(event.currentTarget).attr('task_id')
  if (taskId) {
    openChatWindow(taskId)
  }
}

// 左グリッド: タスク選択 (チャットウィンドウが開いていればロードする)
const _openFromGridSelection = (taskId: any) => {
  if ($('.home_projwin').hasClass('isProjOpen')) {
    openChatWindow(taskId)
  }
}

// 右ツールバー: チャット開く
const _openFromToolbar = () => {
  let taskId = gantt.getSelectedId()
  if (taskId == undefined) {
    const task = gantt.getTaskByIndex(0)
    if (task) {
      taskId = task.id
    }
  }
  if (taskId == undefined) {
    showErrorAlert('タスクを選択してください。')
    return
  }
  openChatWindow(taskId)
}


// チャットウィンドウを開く
// CSSアニメーション（.3s）が終了してから描画する
export const openChatWindow = (taskId: string) => {
  if (taskId == currentChatTaskId) { return }
  currentChatTaskId = taskId
  gantt.selectTask(taskId)

  // gantt.selectTask(taskId)
  $('.home_projwin').addClass('isProjOpen')
  setTimeout(() => { _renderChatComponent(taskId) }, 300)
}

// チャットウィドウを閉じる
export const closeChatWindow = () => {
  currentChatTaskId = undefined
  $('.home_projwin').removeClass('isProjOpen')
  setTimeout(() => { _removeChatComponent(), 300 })
}

// Pusherからの通知時、該当チャットを開いている状態であればリロードする
export const reloadChatIfNeeded = (pusherData: any) => {
  if(!$('.home_projwin').hasClass('isProjOpen')) { return }

  const currentId = gantt.getSelectedId()
  const projectId = pusherData.project_id
  const taskId = pusherData.task_id
  
  if (taskId) {
    if (currentId != `task-${taskId}`) {
      return
    }
  } else {
    if (currentId != projectId) {
      return
    }
  }
  // console.log(`reload current=${currentId}, pro=${projectId}, task=task-${taskId}`)
  $(document).trigger('reloadChat')
}

const _renderChatComponent = (targetTaskId: string) => {
  const task = gantt.getTask(targetTaskId)

  let type = 'project'
  let projectId = task.id
  let taskId = task.id
  let projectName = ''
  let color = task.color_theme
  const taskName = task.text

  if (task.type != gantt.config.types.project) {
    const parent = gantt.getTask(task.parent)
    type = 'task'
    projectId = parent.id
    taskId = Number(task.id.replace('task-', ''))
    projectName = parent.text
    color = parent.color_theme
  }

  _removeChatComponent()
  const node = document.getElementById(ChatElementName)

  ReactDOM.render(<ChatComponent
    closeChatWindow={closeChatWindow}
    color={color}
    taskType={type}
    taskName={taskName}
    taskId={taskId}
    projectId={projectId}
    projectName={projectName}
  />, node)
}

const _removeChatComponent = () => {
  const node = document.getElementById(ChatElementName)
  if (node) {
    ReactDOM.unmountComponentAtNode(node)
    node.innerHTML = ''
  }
}