import { log } from '../util/logger'
import { gantt } from 'dhtmlx-gantt'
import { Fetcher } from '../util/fetcher'
import { ignoreError } from '../util/alert'

let dataCache: {}


export const reloadChatsCount = () => {
  _getCounts()
}

// 全てのチャットカウントをAPIからリロード
const _getCounts = async () => {
  dataCache = {}
  try {
    const response = await Fetcher('get', '/chats/count', null, null)
    response.data.forEach(data => {
      dataCache[`${data.pid}-${data.tid}`] = data.count
    })
    _renderCounts()

  } catch (error) {
    ignoreError(error)
    _renderCounts()
  }
}

// gantt.render()をせずにチャットの数字のみ書き換え
// task.chat_countに値を入れておくと、gantt.render()でも反映される
const _renderCounts = () => {
  gantt.eachTask(task => {
    const key = chatCountKey(task)
    task.chat_count = dataCache[key] | 0
    $(`#cc-${key}`).html(task.chat_count)
  })
}

// チャット一覧取得時に該当チャットの数字置き換え
export const replaceCount = (type: string, projectId: string, taskId: string, count: number) => {
  const key = _chatCountKey(type, projectId, taskId)
  dataCache[key] = count
  _renderCounts()
}

// チャット投稿時に1追加
export const addCount = (type: string, projectId: string, taskId: string) => {
  const key = _chatCountKey(type, projectId, taskId)
  const count = (dataCache[key] | 0) + 1
  dataCache[key] = count
  _renderCounts()
}

// チャット削除時に1追加
export const deleteCount = (type: string, projectId: string, taskId: string) => {
  const key = _chatCountKey(type, projectId, taskId)
  let count = (dataCache[key] | 0) - 1
  if (count < 0) { count = 0 }  
  dataCache[key] = count
  _renderCounts()
}

const _chatCountKey = (type: string, projectId: string, taskId: string): string => {
  let key = ''
  if (type == 'project') {
    key = `${projectId}-0`
  } else {
    key = `${projectId}-${taskId}`
  }
  return key
}

export const chatCountKey = (task: any): string => {
  let key = ''
  if (task.type == gantt.config.types.project) {
    key = `${task.id}-0`
  } else {
    const tid = task.id.replace('task-', '')
    key = `${task.parent}-${tid}`
  }
  return key
}