import { gantt } from 'dhtmlx-gantt'
import { Fetcher } from '../util/fetcher'
import { isOwner, addTaskToGantt, deleteTaskFromGantt, updateDateRange } from './util'
import { showModal, hideModal, hideAllModal, showAlertModal, hideAlertModal } from './modal'
import { shiftMilestone } from './util'
import { clearDataCache } from './project'
import { showErrorAlert, ignoreError } from '../util/alert'


let activeProjectId: number | undefined
let activeTaskId: string | undefined


export const configureTasks = () => {
  configureDOMEvents()
}


// -------------------------------
// jQueryイベントの設定
// -------------------------------

const configureDOMEvents = () => {

  // 左グリッドの要素はdocumentに対してイベントを設定する必要がある
  // タスクの追加
  $(document).on('click', '.left-column-add-task', (event: any) => {
    const taskId = $(event.currentTarget).attr('task_id')
    _showNewTaskModal(Number(taskId))
  })

  // モーダル内のボタン
  $('.mdl_task_save_btn').on('click', _onTaskSaveClicked)
  $('.mdl_task_delete').on('click', _alertDeleteTask)

  // タスク, MS切り替え
  $('.custom-task-control[name=input_task_kind]').on('change', _onTaskSwitchType)
}


// -------------------------------
// APIアクセス
// -------------------------------

const _onTaskSaveClicked = async (event: JQuery.Event) => {
  const projectId = activeProjectId
  if (!projectId) { return }

  const params = {
    name: _getTaskTitleInput(),
    type: _getTaskTypeInput(),
    color: _getTaskColorInput(),
    start_at: _getTaskStartDateInput(),
    end_at: _getTaskEndDateInput()
  }
  if (!params['name']) {
    showErrorAlert('タイトルを入力してください。')
    return
  }
  if (params.type == 1) { // milestone
    params.end_at = params.start_at        
  }
  try {
    if (activeTaskId) {
      const id = activeTaskId.replace('task-', '')
      const response = await Fetcher('put', `/tasks/${id}`, null, params)

      let task = gantt.getTask(activeTaskId)
      task.text = response.data.text
      task.type = response.data.type
      task.color_theme = response.data.color_theme
      task.start_date = new Date(response.data.start_for_parse)
      task.end_date = new Date(response.data.end_for_parse)

      gantt.updateTask(activeTaskId, task)
      shiftMilestone(activeTaskId)
      updateDateRange()

    } else {
      const response = await Fetcher('post', `/projects/${projectId}/tasks`, null, params)
      addTaskToGantt(response.data)
    }

    hideModal()
    clearDataCache()      

  } catch (error) {
    showErrorAlert(error)
  }
}

const _deleteActiveTask = async () => {
  if (activeTaskId == undefined) { return false }
  const id = activeTaskId.replace('task-', '')

  try {
    const response = await Fetcher('delete', `/tasks/${id}`, null, null)

    deleteTaskFromGantt(activeTaskId)
    clearDataCache()      

  } catch (error) {
    showErrorAlert(error)
  }
  hideAllModal()
}


// -------------------------------
// タスクの編集
// -------------------------------

const _showNewTaskModal = (id: number) => {
  const task = gantt.getTask(id)
  if (!isOwner(task)) { return }
  activeProjectId = id
  activeTaskId = undefined
  _configureModalTask()
}

export const showEditTaskModal = (id: string) => {
  const task = gantt.getTask(id)
  if (!isOwner(task)) { return }
  activeProjectId = gantt.getTask(task.parent).id
  activeTaskId = id
  _configureModalTask(task)
}

const _configureModalTask = (task?: any) => {
  if (task) {
    _filloutTaskInput(task)
    _setModalTitle(task.text)
    _onTaskSwitchType()
    $('.modal_task_edit_footer').show()

  } else {
    _resetModal()
    _setModalTitle('新規タスク')
    _onTaskSwitchType()
    $('.modal_task_edit_footer').hide()
  }
  showModal('#home_modal_task')
}

const _alertDeleteTask = () => {
  if (activeTaskId == undefined) { return false }
  const task = gantt.getTask(activeTaskId)

  showAlertModal('タスクの削除', `${task.text}を削除してよろしいですか?`, () => {
    _deleteActiveTask()
  })
}


// -------------------------------
// フォームの値 操作
// -------------------------------

// ウィンドウのタイトル
const _setModalTitle = (val: string) => {
  $('#home_modal_task .modal_home_ttl').html(val)
}

// Task: タイトル
const _setTaskTitleInput = (val: string) => {
  $('#create-taskInput').val(val)
}
const _getTaskTitleInput = (): any => {
  return $('#create-taskInput').val()
}

// Task: 種別
const _setTaskTypeInput = (index: number) => {
  $($('.custom-task-control[name=input_task_kind]')[index]).prop('checked', true)
}
const _getTaskTypeInput = (): any => {
  return $('.custom-task-control[name=input_task_kind]:checked').val()
}

const _onTaskSwitchType = () => {
  if (_getTaskTypeInput() == '0') {
    $('.task_modal_period_col_end, .task_modal_period_between').show()
  } else {
    $('.task_modal_period_col_end, .task_modal_period_between').hide()
  }  
}


// Task: カラー
const _setTaskColorInput = (index: number) => {
  // Projectのtheme_colorに変更
  if (activeProjectId != undefined) {
    const theme = gantt.getTask(activeProjectId).color_theme
    $('#ref-parent-theme').html(`<div class="colorbox theme_clr${theme}" />`)
  }
  $($('.custom-control-input[name=task_color_theme_c]')[index]).prop('checked', true)
}
const _getTaskColorInput = (): any => {
  return $('.custom-control-input[name=task_color_theme_c]:checked').val()
}

// Task: 開始日
const _setTaskStartDateInput = (date: Date) => {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()

  $('.task_modal_period select[name=start_y]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == y)
  })
  $('.task_modal_period select[name=start_m]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == m)
  })
  $('.task_modal_period select[name=start_d]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == d)
  })
}
const _getTaskStartDateInput = (): string => {
  const y = $('.task_modal_period select[name=start_y]').val()
  const m = $('.task_modal_period select[name=start_m]').val()
  const d = $('.task_modal_period select[name=start_d]').val()
  return `${y}/${m}/${d}`
}

// Task: 終了日
const _setTaskEndDateInput = (date: Date) => {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()

  $('.task_modal_period select[name=end_y]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == y)
  })
  $('.task_modal_period select[name=end_m]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == m)
  })
  $('.task_modal_period select[name=end_d]').children().each((index, element) => {
    $(element).prop('selected', $(element).val() == d)
  })
}
const _getTaskEndDateInput = (): string => {
  const y = $('.task_modal_period select[name=end_y]').val()
  const m = $('.task_modal_period select[name=end_m]').val()
  const d = $('.task_modal_period select[name=end_d]').val()
  return `${y}/${m}/${d}`
}

const _filloutTaskInput = (task: any) => {
  _setTaskTitleInput(task.text)
  _setTaskTypeInput((task.type == 'task') ? 0 : 1)
  _setTaskColorInput(task.color_theme)
  _setTaskStartDateInput(task.start_date)
  _setTaskEndDateInput(task.end_date)
}

const _resetModal = () => {
  _setTaskTitleInput('')
  _setTaskTypeInput(0)
  _setTaskColorInput(0)
  const date = new Date()
  _setTaskStartDateInput(date)
  _setTaskEndDateInput(date)
}
