import { gantt } from 'dhtmlx-gantt'
import { Fetcher } from '../util/fetcher'
import { isOwner, addTaskToGantt, deleteTaskFromGantt, shiftAllMilestones, updateDateRange } from './util'
import { showModal, hideModal, hideAllModal, showAlertModal, hideAlertModal, showArchiveModal, showArchiveFreeModal, showOtherMemberLockedModal } from './modal'
import { loadProjectList, filteredProjectIds } from './project_list'
import { reloadChatsCount } from '../chat/chat_count'
import { showErrorAlert } from '../util/alert'


let activeProjectId: number | undefined
let dataCache: GanttData | undefined


export const configureProjects = () => {
  configureDOMEvents()
}


// -------------------------------
// Interface
// -------------------------------

interface GanttData {
  data: (Project | ProjectTask)[]
  links: TaskLink[]
}

interface Project {
  id: number
  parent: number
  type: string
  root: boolean
  text: string
  color_theme: number
  open: boolean
  is_owner: boolean
}

interface ProjectTask {
  id: string
  parent: number
  type: string
  root: boolean
  text: string
  color_theme: number
  start_date: string
  end_date: string
  start_for_parse: string
  end_for_parse: string
}

interface TaskLink {
  id: number
  target: string
  source: string
  type: string
}

interface Member {
  id: number
  status: string
  name: string
  email: string
  image: string
  myself: boolean
  company_member: boolean
}


// -------------------------------
// jQueryイベントの設定
// -------------------------------

const configureDOMEvents = () => {

  // 左グリッドの要素はdocumentに対してイベントを設定する必要がある
  // プロジェクトの編集
  $(document).on('click', '.left-column-config', (event: any) => {
    const taskId = $(event.currentTarget).attr('task_id')
    showEditProjectModal(Number(taskId))
  })

  // プロジェクト作成
  $('.js_modal_project').on('click', _showNewProjectModal)

  // モーダル内のタブ切り替え
  $('.js_proj_info').on('click', _openProjectInfoTab)
  $('.js_proj_join').on('click', _openProjectMembersTab)

  // モーダル内のボタン
  $('.mdl_project_save_btn').on('click', _onProjectSaveClicked)
  $('.mdl_proj_delete').on('click', _alertDeleteProject)
  $('.mdl_proj_archive').on('click', _alertArchiveProject)
  $('.mdl_proj_reset_key').on('click', _resetInvitationKey)
  $('.projlink_copybtn').on('click', _copyInvitationLink)
  $('.permit_btn').on('click', _switchMemberPermission)
}


// -------------------------------
// プロジェクトの描画
// -------------------------------

export const renderProjects = () => {
  // 初回ロード時
  if (dataCache === undefined) {
    _loadProjects()
    return
  }

  // Project
  const filteredIds = filteredProjectIds()
  let projects = dataCache.data.filter(task => {
    if (task.root && typeof task.id === 'number') {
      return filteredIds.includes(task.id)
    }
    return false
  })
  projects = projects.sort((n1: any, n2: any) => {
    const idx1 = filteredIds.indexOf(n1.id)
    const idx2 = filteredIds.indexOf(n2.id)
    if (idx1 > idx2) { return 1 }
    if (idx1 < idx2) { return -1 }
    return -1
  })

  // Task
  let tasks = dataCache.data.filter(task => {
    return !task.root && filteredIds.includes(task.parent)
  })
  let taskIds = tasks.map(task => {
    return task.id
  })

  // Link
  let links = dataCache.links.filter(link => {
    return taskIds.includes(link.source) || taskIds.includes(link.target)
  })

  // Render Gantt
  gantt.clearAll()
  gantt.parse({ data: projects.concat(tasks), links: links })

  shiftAllMilestones()
  updateDateRange()
}

export const clearDataCache = () => {
  dataCache = undefined
}


// -------------------------------
// APIアクセス
// -------------------------------

const _loadProjects = async () => {
  try {
    const response = await Fetcher('get', `/projects/gantt`, null, null)
    dataCache = response.data

    renderProjects()
    reloadChatsCount()
  
  } catch (error) {
    showErrorAlert(error)
  }
}

const _onProjectSaveClicked = async (event: JQuery.Event) => {
  const params = {
    name: _getProjectTitleInput(),
    color: _getProjectColorInput()
  }
  if (!params['name']) {
    showErrorAlert('タイトルを入力してください。')
    return
  }
  try {
    if (activeProjectId) {
      const response = await Fetcher('put', `/projects/${activeProjectId}`, null, params)

      let task = gantt.getTask(activeProjectId)
      task.text = response.data.text
      task.color_theme = response.data.color_theme
      gantt.updateTask(String(activeProjectId), task)
      gantt.render() // project配下のtaskにもカラーを即時適応

    } else {
      const response = await Fetcher('post', `/projects`, null, params)
      addTaskToGantt(response.data)
    }
    hideModal()

    loadProjectList()
    clearDataCache()

  } catch (error) {
    showErrorAlert(error)
  }
}

const _deleteActiveProject = async () => {
  if (activeProjectId == undefined) { return false }

  try {
    const response = await Fetcher('delete', `/projects/${activeProjectId}`, null, null)
    deleteTaskFromGantt(`${activeProjectId}`)

    loadProjectList()
    clearDataCache()

  } catch (error) {
    showErrorAlert(error)
  }
  hideAllModal()
}

const _archiveActiveProject = async () => {
  if (activeProjectId == undefined) { return false }

  try {
    const response = await Fetcher('put', `/projects/${activeProjectId}/archive`, null, null)
    deleteTaskFromGantt(`${activeProjectId}`)

    loadProjectList()
    clearDataCache()

  } catch (error) {
    showErrorAlert(error)
  }
  hideAllModal()
}

export const loadMembers = async (projectId: number) => {
  activeProjectId = projectId
  try {
    const response = await Fetcher('get', `/projects/${projectId}/members`, null)
    _renderMembers(response.data.members, projectId)

    const data = response.data.project
    _setProjectLinkText(data.invite_url, data.invite_limit)
    _setMemberPermissionInput(data.allow_other_company)

  } catch (error) {
    showErrorAlert(error)
  }
}

const _deleteMember = async (projectId: number, event: any, refresh: boolean) => {
  const user_project_id = $(event.target).data('id')
  try {
    await Fetcher('delete', `/project_members/${user_project_id}`, null, null)
    refresh ? location.reload() : loadMembers(projectId)

  } catch (error) {
    showErrorAlert(error)
  }
}

const _resetInvitationKey = async () => {
  if (activeProjectId == undefined) { return }
  try {
    const response = await Fetcher('put', `/projects/${activeProjectId}/reset_secret`, null, null)

    const data = response.data
    _setProjectLinkText(data.invite_url, data.invite_limit)

  } catch (error) {
    showErrorAlert(error)
  }
}

const _switchMemberPermission = async () => {
  if (activeProjectId == undefined) { return }

  // Freeユーザーブロック
  let task = gantt.getTask(activeProjectId)
  if (task.plan == 0) {
    showOtherMemberLockedModal(() => {
      window.location.href = '/plan';
    });
    return;
  }

  // 切り替え実行
  $('.permitrange').toggleClass('permit_off')
  try {
    const response = await Fetcher('put', `/projects/${activeProjectId}/allow_other_company`, null, {
      value: _getMemberPermissionInput()
    })
    const data = response.data
    _setMemberPermissionInput(data.allow_other_company)

  } catch (error) {
    showErrorAlert(error)
  }
}


// -------------------------------
// 基本情報とメンバーのタブ切り替え
// -------------------------------

const _openProjectInfoTab = () => {
  $('.js_proj_info').removeClass('tab_active').addClass('tab_active')
  $('.js_proj_join').removeClass('tab_active')
  $('.project_tab_info').fadeIn(400)
  $('.project_tab_list').fadeOut(0)
}

const _openProjectMembersTab = () => {
  $('.js_proj_info').removeClass('tab_active')
  $('.js_proj_join').removeClass('tab_active').addClass('tab_active')
  $('.project_tab_info').fadeOut(0)
  $('.project_tab_list').fadeIn(400)
}

// プロジェクト基本情報表示
const _showProjectInfoInModal = () => {
  $('.project_tab_list').hide()
  $('.tab_list, .project_out_permit, .project_tab_info, .project_link_copy').show()
}

// メンバーリストだけ表示
const _hideProjectInfoInModal = () => {
  $('.project_tab_list').fadeIn(400)
  $('.tab_list, .project_out_permit, .project_tab_info, .project_link_copy').hide()
}


// -------------------------------
// プロジェクトの編集
// -------------------------------

const _showNewProjectModal = () => {
  activeProjectId = undefined
  _configureModalProject()
}

export const showEditProjectModal = (id: number) => {
  const task = gantt.getTask(id)
  activeProjectId = id
  _configureModalProject(task)
}

const _configureModalProject = (task?: any) => {
  if (task) {
    _openProjectInfoTab()
    _filloutProjectInput(task)
    _setModalTitle(task.text)
    $('.project_create_tab, .modal_project_edit_footer').show()
    isOwner(task) ? _showProjectInfoInModal() : _hideProjectInfoInModal()
    loadMembers(task.id)

  } else {
    _resetModal()
    _setModalTitle('新規プロジェクト')
    $('.project_create_tab, .modal_project_edit_footer').hide()
    _showProjectInfoInModal()
  }
  showModal('#home_modal_project')
}

const _alertDeleteProject = () => {
  if (activeProjectId == undefined) { return false }
  const task = gantt.getTask(activeProjectId)
  if (!isOwner(task)) { return }

  showAlertModal('プロジェクトの削除', `${task.text}を削除してよろしいですか?`, () => {
    _deleteActiveProject()
  })
}

const _alertArchiveProject = () => {
  if (activeProjectId == undefined) { return false }
  const task = gantt.getTask(activeProjectId)
  if (!isOwner(task)) { return }

  if (task.plan == 1) {
    showArchiveModal('プロジェクトをアーカイブ', `${task.text}をアーカイブしてよろしいですか?`, () => {
      _archiveActiveProject()
    })
  } else {
    showArchiveFreeModal(() => {
      window.location.href = '/plan';
    })  
  }
}



// -------------------------------
// メンバーの編集
// -------------------------------

const _renderMembers = (members: Member[], projectId: number) => {
  const list = $('#home_modal_project ul.proj_list')
  list.empty()

  const task = gantt.getTask(projectId)
  const isTaskOwner = isOwner(task)

  members.forEach((e) => {
    const isOwnerRow = e.status == 'owner'
    const text1 = isTaskOwner ? 'この参加者を削除' : 'このプロジェクトから退出'

    let text2 = 'メンバー'
    if (isOwnerRow) {
      text2 = 'オーナー'
    } else if (!e.company_member) {
      text2 = '社外メンバー'
    }

    let deleteLink = `
      <a href="#"
        class="proj_list_destroy"
        data-id="${e.id}" 
        data-user="${e.name}" 
        data-content="${text1}" 
        data-parts="projlist-delete"></a>`
    
    if (isTaskOwner) {
      if (isOwnerRow) { deleteLink = '' }
    } else {
      if (!e.myself) { deleteLink = '' }
    }

    let imageTag = `<img src="${e.image}">`
    if (e.image.includes('icon_ws_person.svg')) {
      imageTag = `<div class="user-image-tag">${e.name[0]}</div>`
    }

    list.append(`
    <li class="proj_owner">
      <div class="proj_list_pic">${imageTag}</div>
      <div class="proj_list_detail">
        <p class="pj_name">${e.name}</p>
        <p class="pj_mail">${e.email}</p>
      </div>
      <div class="proj_list_status">
        ${deleteLink}
        <p class="pj_status">${text2}</p>
      </div>
    </li>`)
  })

  $('[data-parts="projlist-delete"]').ggpopover({
    container: '#home_modal_project',
    html: true,
    placement: 'bottom',
    trigger: 'hover'
  })

  $('.proj_list_destroy').off('click').on('click', (event) => {
    let title = 'プロジェクトから退出'
    let body = `${$('#create-titleInput').val()}から退出してよろしいですか?`

    if (isTaskOwner) {      
      title = '参加者を削除'
      body = `${$(event.target).data('user')}を削除してよろしいですか?`
    }
    
    showAlertModal(title, body, () => {
      _deleteMember(projectId, event, !isTaskOwner)
      hideAlertModal()
    })
  })
}


// -------------------------------
// アーカイブ
// -------------------------------



// -------------------------------
// 招待URL
// -------------------------------

const _copyInvitationLink = () => {
  const $textarea = $('#project_link_txt')
  $textarea.select()
  document.execCommand('copy')
}


// -------------------------------
// フォームの値 操作
// -------------------------------

// ウィンドウのタイトル
const _setModalTitle = (val: string) => {
  $('#home_modal_project .modal_home_ttl').html(val)
}

// Project: タイトル
const _setProjectTitleInput = (val: string) => {
  $('#create-titleInput').val(val)
}
const _getProjectTitleInput = (): any => {
  return $('#create-titleInput').val()
}

// Project: カラー
const _setProjectColorInput = (index: number) => {
  $($('.custom-control-input[name=color_theme_c]')[index-1]).prop('checked', true)
}
const _getProjectColorInput = (): any => {
  return $('.custom-control-input[name=color_theme_c]:checked').val()
}

// Project Member: プロジェクト招待リンク
const _setProjectLinkText = (val: string, limit: string) => {
  $('#project_link_txt').val(val)
  $('.project_link_limit').html(limit)
}

// 社外メンバー許可 (permit_offが付いていればOFF状態)
const _setMemberPermissionInput = (flag: number) => {
  if (activeProjectId == undefined) { return }
  let task = gantt.getTask(activeProjectId)
  
  $('.permitrange').removeClass('permit_off')
  if (flag == 0 || task.plan == 0) {
    $('.permitrange').addClass('permit_off')
  }
}
const _getMemberPermissionInput = (): number => {
  if ($('.permitrange').hasClass('permit_off')) {
    return 0
  }
  return 1
}


const _filloutProjectInput = (task: any) => {
  _setProjectTitleInput(task.text)
  _setProjectColorInput(task.color_theme)
}

const _resetModal = () => {
  _setProjectTitleInput('')
  _setProjectColorInput(0)
}