import { gantt } from 'dhtmlx-gantt'
import { Fetcher } from '../util/fetcher'
import { clearDataCache } from './project'
import { showErrorAlert, ignoreError } from '../util/alert'

export const configureTaskLinks = () => {

  // リンク: 追加と削除
  gantt.attachEvent("onBeforeLinkAdd", (id, link) => {
    var sourceTask = gantt.getTask(link.source)
    var targetTask = gantt.getTask(link.target)

    // プロジェクトにリンク設定は不可
    if (sourceTask.root || targetTask.root) {
      return false
    }    
    // 権限のないプロジェクトのタスクにリンク設定は不可
    var p1 = gantt.getTask(sourceTask.parent).is_owner
    var p2 = gantt.getTask(targetTask.parent).is_owner
    return p1 == 1 && p2 == 1
  }, null)

  gantt.attachEvent("onAfterLinkAdd", (id, link) => {
    _createTaskLink(link)
  }, null)

  gantt.attachEvent("onBeforeLinkDelete", (id, link) => {
    var sourceTask = gantt.getTask(link.source)
    var targetTask = gantt.getTask(link.target)

    // 権限のないプロジェクトのタスクにリンク変更は不可
    var p1 = gantt.getTask(sourceTask.parent).is_owner
    var p2 = gantt.getTask(targetTask.parent).is_owner
    return p1 == 1 && p2 == 1
  }, null)

  gantt.attachEvent("onAfterLinkDelete", (id, link) => {
    _deleteTaskLink(link)
  }, null)
}


// -------------------------------
// APIアクセス
// -------------------------------

const _createTaskLink = async (link: any) => {
  const project_id = gantt.getTask(link.source).parent
  const from_id = link.source.replace('task-', '')
  const to_id = link.target.replace('task-', '')
  const type = link.type
  
  try {
    const response = await Fetcher('post', `/projects/${project_id}/task_links`, null, {
      from_id: from_id,
      to_id: to_id,
      link_type: type
    })

    gantt.changeLinkId(link.id, response.data.link_id)
    clearDataCache()

  } catch (error) {
    gantt.deleteLink(link.id)
    showErrorAlert(error)
  }
}

const _deleteTaskLink = async (link: any) => {
  try {
    const response = await Fetcher('delete', `/task_links/${link.id}`, null, null)
    clearDataCache()

  } catch (error) {
    showErrorAlert(error)
  }
}