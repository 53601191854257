import { endpoint } from './env'
import { log } from './logger'
import axios from 'axios'

let apiToken: string = ''

/**
 * APIアクセス
 * @param method 'get' or 'post' or ..
 * @param url baseURLを除外したURL
 * @param params URLに追加するGETパラメータ
 * @param body RequestBodyに追加するPOSTパラメータ
 */
export const Fetcher = async (method: string, url: string, params?: any, body?: any, withFile?: boolean, onUploadProgress?: (e: any) => void) => {
  const object = new RequestObject(method, url, params, body, withFile, onUploadProgress)
  return await Fetch(object)
}

// APIトークンを取得
export const FetchToken = async () => {
  try {
    const response = await axios.get(`${endpoint()}/auth_web/token`, {})
    apiToken = response.data.token
    loggerSuccess(response)
  } catch (error) {
    loggerError(error.response)
  }
}

const Fetch = async (object: RequestObject): Promise<any> => {
  try {
    // Webログイン経由でAPIトークンを取得
    if (apiToken == '') {
      await FetchToken()
    }

    const request: any = await object.createRequest()
    loggerRequest(request)

    const response = await axios(request)
    loggerSuccess(response)
    return Promise.resolve(response)

  } catch (error) {
    loggerError(error.response)

    // トークン切れの場合はリトライ
    if (needRefreshToken(error)) {
      apiToken = ''
      return await Fetch(object)

    } else {
      return Promise.reject(parseError(error))
    }
  }
}

/**
 * axiosのRequest作成クラス
 */
class RequestObject {
  method: string
  url: string
  params?: any
  body?: any
  withFile?: boolean
  onUploadProgress?: (e: any) => void

  constructor(method: string, url: string, params?: any, body?: any, withFile?: boolean, onUploadProgress?: (e: any) => void) {
    this.method = method
    this.url = url
    this.params = params
    this.onUploadProgress = onUploadProgress
    this.body = null
    if (body) {
      this.body = withFile ? body : JSON.stringify(body)
    }
  }

  createRequest = async () => {
    const request: any = {
      baseURL: endpoint(),
      url: this.url,
      method: this.method,
      params: this.params,
      data: this.body,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-GANTT-TOKEN': apiToken
      },
      validateStatus: function (status: number) {
        return status < 400 // HTTP Status 400以上はエラー
      },
      onUploadProgress: this.onUploadProgress
    }
    return request
  }
}

const parseError = (error: any): any => {
  let message = '通信エラーが発生しました'

  if (!error.response) {
    return { status: 0, message: message }
  }
  const response = error.response
  const status = response.status
  if (!response.data) {
    return { status: status, message: message }
  }

  const data = response.data
  if (data.message) {
    message = `${message}\n${data.message}`
  }
  return { status: status, message: message }
}

const needRefreshToken = (error: any): boolean => {
  if (!error.response) { return false }

  const response = error.response
  const status = response.status
  if (status != 401) { return false }

  if (!response.data || !response.data.refresh_token) { return false }
  return response.data.refresh_token == 1
}

// const sleep = (msec: number) => {
//   new Promise(resolve => setTimeout(resolve, msec))
// }

const loggerRequest = (params: any) => {
  const styles1 = 'font-weight: bold;line-height: 1.3; background: #E7E7E7; padding-top: 2px;';
  const styles2 = 'font-weight: bold;line-height: 2;';
  log('')
  log(`%c ${params.method.toUpperCase()} %c ${params.url}`, styles1, styles2, params)
}

const loggerSuccess = (params: any) => {
  const styles1 = 'font-weight: bold;line-height: 2; color: blue;';
  const styles2 = 'font-weight: bold;line-height: 2;';
  log('')
  log(`%c${params.status} %c${params.config.url}`, styles1, styles2, params.data)
}

const loggerError = (params: any) => {
  const styles1 = 'font-weight: bold;line-height: 2; color: red;';
  const styles2 = 'font-weight: bold;line-height: 2;';
  log('')
  log(`%c${params.status} %c${params.config.method.toUpperCase()} ${params.config.url}`, styles1, styles2, params.data)
}