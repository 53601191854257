import React, { useState, useEffect, useRef } from 'react'
import { Fetcher } from '../util/fetcher'
import { addCount, deleteCount, replaceCount } from './chat_count'
import { updateMessageOnlyFront } from './message'
import { showErrorAlert, ignoreError } from '../util/alert'
import { formatDate, formatDatetime } from '../util/date'
import TextareaAutosize from 'react-textarea-autosize'
import { log } from '../util/logger'


type Props = {
  taskType: string
  taskName: string
  taskId: number
  projectId: number
  projectName: string
  closeChatWindow: () => void
  color: number
}


export const ChatComponent = (props: Props) => {

  // fetch data
  const [messages, setMessages] = useState<any>([])
  const [projectMembers, setProjectMembers] = useState<any>([])

  // Plan limitation
  const [isLimited, setIsLimited] = useState(false)
  const [reachFreeLimit, setReachFreeLimit] = useState(false)

  // input data
  const [file, setFile] = useState<any>(null)
  const [text, setText] = useState('')

  // loading
  const [likeLoading, setLikeLoading] = useState(false)
  const [fileUploading, setFileUploading] = useState(false)
  const [fileUploadProgress, setFileUploadProgress] = useState(0)

  // filter
  const [filtered, setFiltered] = useState(false)

  // modal 
  const [showAtList, setShowAtList] = useState(false)

  // refs
  const inputFileRef = useRef(null)
  const lastChatRef = useRef(null)
  const inputTextRef = useRef(null)


  useEffect(() => {
    log(`ChatCompo Mounted pid=${props.projectId}, tid=${props.taskId}`)
    _fetchChat()

    // Pusherからのイベントでリロード
    // React Componentの外からコンポーネント内のメソッドを呼びたいのでDOMイベントで繋ぐ
    $(document).on('reloadChat', () => {
      _fetchChat()
    })

    return () => {
      log(`ChatCompo Un Mounted pid=${props.projectId}, tid=${props.taskId}`)
      $(document).off('reloadChat')
    }
  }, [])


  //**************************
  // util
  //**************************

  const groupMessagesByDate = (messages: any[]) => {
    const result = {}
    messages.map((message) => {
      const date = message.created_at.slice(0, 10)
      if (result[date]) result[date].push(message)
      else result[date] = [message]
    })
    return result
  }

  const createUserPopupHtml = (chat_likes) => {
    let html = '<ul>'
    if (chat_likes.length > 0) {
      chat_likes.map(like => html += `<li>${like.user.name}</li>`)
    } else {
      html += '<li>0人です</li>'
    }
    html += '</ul>'
    return html
  }

  const clearFile = () => {
    setFile(null)
    inputFileRef.current.value = null
  }


  //**************************
  // event
  //**************************

  const _deleteFile = () => {
    if (confirm("添付をキャンセルしますか？")) {
      clearFile()
    }
  }

  const _onChangeText = (e: any) => {
    const target = e.target as HTMLInputElement
    setText(target.value)
  }

  const onClickUploadIcon = () => {
    const node = inputFileRef.current
    if (node) { node.click() }
  }

  const _onChangeFile = (e: any) => {
    const target = e.target as HTMLInputElement

    if (target.files && target.files.length > 0) {
      setFile(target.files[0])
    }
  }

  const _onStampMouseOver = (chatId, msgCount) => {
    if (!likeLoading && msgCount > 0) _fetchLike(chatId)
  }

  const _onClickStamp = (chatId: number, isLiked: boolean, e: any) => {
    isLiked ? _deleteLike(chatId) : _postLike(chatId)
  }

  const onClickDelete = (chatId: number) => {
    if (confirm("メッセージを削除してよろしいですか？")) {
      //すぐ削除するとpopoverが消えないのでちょっと待つ
      setTimeout(() => _deleteChat(chatId), 150)
    }
  }

  const _toggleFilter = () => {
    setFiltered(!filtered)
  }

  const _onClickAt = () => {
    setShowAtList(true)
  }

  const _onBlurText = () => {
    setShowAtList(false)
  }

  const _onClickAtList = (name: string, e: any) => {
    const node = inputTextRef.current
    if (node) {
      const index = node.selectionStart // 挿入位置 
      const mention = name.replace(/\s\(.*\)$/, '') // 社名を削除
      setText(text.substr(0, index) + `@${mention}` + text.substr(index))
      setShowAtList(false)
    }
  }

  const onUploadProgress = (e) => {
    setFileUploadProgress(Math.floor((e.loaded * 100) / e.total))
  }


  //**************************
  // Api
  //**************************

  const _fetchChat = async () => {
    try {
      const response = await Fetcher('get', `/chats/${props.taskType}/${props.taskId}`, null, null)

      const count_all = response.data.count_all
      const limited = response.data.limited
      const chats = response.data.chats

      // 無料プランで10件超えている場合は制限あり
      setIsLimited(limited)
      setReachFreeLimit(limited && count_all >= 10)

      setMessages(chats)
      setProjectMembers(response.data.members)
      replaceCount(`${props.taskType}`, `${props.projectId}`, `${props.taskId}`, count_all)
      scrollChat('auto')
      setPopOverEvent()

      //通知の更新
      const taskIdForUpdate = props.taskType == 'project' ? null : props.taskId
      updateMessageOnlyFront(props.projectId, taskIdForUpdate);

    } catch (error) {
      showErrorAlert(error)
    }
  }

  const _postChat = async (e: any) => {
    e.preventDefault()

    if (!sendable()) {
      showErrorAlert('メッセージか添付ファイルを選択してください。')
      return false
    }

    // 容量チェック
    if (file) {
      if (isLimited) {
        if (file.size / (1024 * 1024) > 100) {
          showErrorAlert('Freeプランでは100MBまでのファイルに制限されています。')
          return
        }
      } else {
        if (file.size / (1024 * 1024) > 1000) {
          showErrorAlert('1GBを超えるファイルは添付出来ません。')
          return
        }
      }
    }

    const data = new FormData()
    data.append('text', text)
    if (file) {
      data.append('uploaded_image', file || '')
      setFileUploading(true);
    }

    try {
      const response = await Fetcher('post', `/chats/${props.taskType}/${props.taskId}`, null, data, true, onUploadProgress)
      //clear
      setText('')
      clearFile()

      //update
      const newMessages = [...messages, response.data]
      setMessages(newMessages)
      scrollChat('smooth')
      setPopOverEvent()
      addCount(`${props.taskType}`, `${props.projectId}`, `${props.taskId}`)

    } catch (error) {
      showErrorAlert(error)

    } finally {
      setFileUploading(false)
      setFileUploadProgress(0)
    }
  }

  const scrollChat = (behavior: string = 'auto') => {
    if (lastChatRef.current) lastChatRef.current.scrollIntoView({ behavior: behavior, block: 'end' })
  }

  const sendable = () => {
    return text !== '' || file != null
  }

  const _deleteChat = async (chatId: number) => {
    try {
      await Fetcher('delete', `/chats/${chatId}`, null, null, true)
      const newMessages = messages.filter((message) => message.id != chatId)
      $(`#message-${chatId}`).fadeOut(250, () => {
        setMessages(newMessages)
      })
      deleteCount(`${props.taskType}`, `${props.projectId}`, `${props.taskId}`)

    } catch (error) {
      showErrorAlert(error)
    }
  }

  // star
  const _postStar = async (chatId) => {
    try {
      const response = await Fetcher('post', `/chats/${chatId}/stars`, null, null)
      //更新
      const newChats = messages.concat()
      let chat = newChats.find(c => c.id == chatId)
      chat.is_stared = true
      setMessages(newChats)

    } catch (error) {
      showErrorAlert(error)
    }
  }

  const _deleteStar = async (chatId: Number) => {
    try {
      const response = await Fetcher('delete', `/chats/${chatId}/stars`, null, null)
      //更新
      const newChats = messages.concat()
      let chat = newChats.find(c => c.id == chatId)
      chat.is_stared = false
      setMessages(newChats)

    } catch (error) {
      showErrorAlert(error)
    }
  }

  //like
  const _fetchLike = async (chatId) => {
    try {
      setLikeLoading(true)
      const response = await Fetcher('get', `/chats/${chatId}/likes`, null, null)
      //更新
      const newChats = messages.concat()
      let chat = newChats.find(c => c.id == chatId)
      chat.chat_likes = response.data.chat_likes
      setMessages(newChats)

    } catch (error) {
      showErrorAlert(error)

    } finally {
      setLikeLoading(false)
    }
  }

  const _postLike = async (chatId) => {
    try {
      const response = await Fetcher('post', `/chats/${chatId}/likes`, null, null)
      //更新
      const newChats = messages.concat()
      let chat = newChats.find(c => c.id == chatId)
      chat.chat_likes = response.data.chat_likes
      chat.is_liked = true
      setMessages(newChats)
      reloadPopOver(chatId)

    } catch (error) {
      showErrorAlert(error)
    }
  }

  const _deleteLike = async (chatId: Number) => {
    try {
      const response = await Fetcher('delete', `/chats/${chatId}/likes`, null, null)
      //更新
      const newChats = messages.concat()
      let chat = newChats.find(c => c.id == chatId)
      chat.chat_likes = response.data.chat_likes
      chat.is_liked = false
      setMessages(newChats)
      reloadPopOver(chatId)

    } catch (error) {
      showErrorAlert(error)
    }
  }


  //**************************
  // return
  //**************************

  const _renderHeader = () => {
    const starIcon = filtered ? '../images/main/icon_chat_star_on.svg' : '../images/main/icon_chat_star_off.svg'

    return (
      <header className={`home-projwin-header chat_theme_clr${props.color}`}>
        <div className="home-projwin-header-title-box">
          <h5 className="home-projwin-header-title">
            {props.projectName.length > 0 &&
              <>
                <span className="task-id" dangerouslySetInnerHTML={{ __html: props.projectName }} />
                <span className="task-name" dangerouslySetInnerHTML={{ __html: props.taskName }} />
              </>
            }
            {props.projectName.length == 0 &&
              <span className="task-name task-name-only" dangerouslySetInnerHTML={{ __html: props.taskName }} />
            }
          </h5>
          <div className='home_projwin_right'>
            <a className="home_projwin_close_new" onClick={props.closeChatWindow}>閉じる</a>
            <img className="home-projwin-header-star-img_new" style={{ width: '1.5rem' }} src={starIcon} onClick={_toggleFilter} />
          </div>
        </div>
        <ul className="home-projwin-header-members">
          {projectMembers.map((user, index) => {
            return <li key={index} className="chatmember">{_userImageTag(user)}</li>
          })}
        </ul>
      </header>
    )
  }

  const _userImageTag = (user: any) => {
    if (user.image.includes('icon_ws_person.svg')) {
      return <div className='user-image-tag small'>{user.name[0]}</div>
    }
    return <img src={user.image} />
  }

  // _render message
  const _renderMessageConts = (date, messages) => {
    return (
      <div key={date} className="chat-messages-conts">
        <div className="chat-datetime"><time>{formatDate(date)}</time></div>
        {messages.map((m, index) => {
          const isLast = (index == messages.length - 1)
          return _renderMessage(m, isLast)
        })}
      </div>
    )
  }

  //1メッセージ
  const _renderMessage = (message, isLast) => {
    const ref = isLast ? lastChatRef : null
    const mineStyle = message.is_mine ? 'mine' : ''
    return (
      <div className={`chat-message ${mineStyle}`} key={message.id} ref={ref} id={`message-${message.id}`}>
        <input className="chat-message-checkstar" id="chat-message-checkstar-0" type="checkbox" />
        <div className="chat-message-wrap">
          <div className="chat-message-userimg" >
            {_userImageTag(message.user)}
          </div>
          <div className="chat-message-content">
            {_renderMessageHeader(message)}
            {_renderMessageBody(message)}
            {_renderMessageFooter(message)}
          </div>
        </div>
      </div>
    )
  }

  const _renderMessageHeader = (chat) => {
    const starIcon = chat.is_stared ? '../images/main/icon_chat_star_on.svg' : '../images/main/icon_chat_star_off.svg'
    return (
      <header className="chat-message-header">
        <p className="name" dangerouslySetInnerHTML={{ __html: chat.user.name }} />
        <time className="timestamp" >{formatDatetime(chat.created_at, true)}</time>
        <nav className="chat-nav">
          <ul>
            {chat.is_mine &&
              <li>
                <a className="chat-nav-destroy"
                  href="#"
                  data-content="メッセージ削除"
                  data-parts="chat-delete"
                  data-target="#home-modal-delmsg"
                  data-toggle="modal"
                  onClick={() => onClickDelete(chat.id)}>
                </a>
              </li>
            }
            <li>
              <img
                className='chat-nav-star-image'
                src={starIcon}
                onClick={() => chat.is_stared ? _deleteStar(chat.id) : _postStar(chat.id)}
              />
            </li>
          </ul>
        </nav>
      </header>
    )
  }

  const _renderMessageBody = (message) => {
    const file = message.chat_files.length > 0 ? message.chat_files[0] : null
    return (
      <div className="chat-message-body">
        <div className="chat-message-text" dangerouslySetInnerHTML={{ __html: message.text }}></div>

        {file &&
          <a className="attachments" target="_blank" href={`/files/${file.id}`}>
            <figure>
              {file.thumb_url &&
                <div>
                  <img src={file.thumb_url} />
                </div>
              }
              <figcaption>{file.filename}<span ></span></figcaption>
            </figure>
          </a>
        }
      </div>
    )
  }

  const _renderMessageFooter = (message) => {
    const stampIcon = message.is_liked ? '../images/main/icon_chat_stamp_on.svg' : '../images/main/icon_chat_stamp_off.svg'

    return (
      <footer className="chat-message-footer">
        <div className="chat-message-footer-stamp">
          <label className="chat-message-footer-stamp-icon "
            data-content={createUserPopupHtml(message.chat_likes)}
            data-parts={'chat-stamp'}
            data-chat-id={message.id}
            onMouseOver={() => _onStampMouseOver(message.id, message.chat_likes.length)}
            onClick={(e) => _onClickStamp(message.id, message.is_liked, e)}
          >
            <img src={stampIcon} />
          </label>
          <span className="chat-message-footer-stamp-count">{message.chat_likes.length}</span>
        </div>
      </footer >
    )
  }

  // メンション候補
  const _renderChatAtConts = () => {
    const showClassName = showAtList ? 'show' : ''
    return (
      <div className={`chat-at-conts ${showClassName}`}>
        <ul className="chat-at-list">

          {projectMembers.map((user, index) => {
            return (
              <li key={index}><a href="#" onClick={(e) => _onClickAtList(user.name, e)}>
                <div className="chat-at-list-img"><img alt="" src={user.image} /></div>
                <p className="chat-at-list-name">{user.name}</p>
              </a></li>
            )
          })}
        </ul>
      </div>
    )
  }

  // メッセージ入力欄
  const _renderTextForm = () => {
    const uploadIcon = file ? '../images/main/icon_chat_upload_on.svg' : '../images/main/icon_chat_upload.svg'
    const onClickUpload = file ? _deleteFile : onClickUploadIcon
    const sendButtonClass = sendable() ? 'ready' : ''

    if (reachFreeLimit) {
      return (
        <div>
          <div style={{ fontWeight: 'bold', padding: '40px', color: '#1E6DFA', borderTop: '1px solid #ddd' }}>
            Freeプランユーザーが作成したプロジェクトのチャット投稿は各タスク10回までです。
            Proプランにアップグレードすると制限が解除されます。
          </div>
        </div>
      )
    }

    return (
      <form className="tab-pane-form">
        { fileUploading == true && _renderProgress()}
        <div className="tab-pane-form-text">
          <TextareaAutosize
            id="chat-form-text"
            name="chat_text"
            placeholder="メッセージを入力"
            autoFocus={true}
            value={text}
            onChange={_onChangeText}
            ref={inputTextRef}
            onBlur={_onBlurText}
            maxRows={20}
            minRows={5}
          />
          <a className="tab-pane-form-text-at" href="#" onClick={_onClickAt}>
            <img alt="" src="images/main/icon_at.svg" />
          </a>
        </div>

        <label ><img src={uploadIcon} onClick={onClickUpload} /></label>
        <input ref={inputFileRef} style={{ display: 'none' }} id="fileupload" name="attachment" type="file" onChange={_onChangeFile} />
        <button className={`tab-pane-form-send-new ${sendButtonClass}`} onClick={_postChat}>送信</button>
      </form>
    )
  }

  const _renderProgress = () => {
    return (
      <div className='progress'>
        <p className='progress-text'>
          {fileUploadProgress < 100 && `ファイルを送信中.. ${fileUploadProgress}%`}
          {fileUploadProgress >= 100 && `処理中..`}
        </p>
      </div>
    )
  }

  const msgs = filtered ? messages.filter(m => m.is_stared) : messages
  const convertedMessages = groupMessagesByDate(msgs)

  return (
    <div className="home-projwin-body">
      {_renderHeader()}
      <div className="tab-content-chat">
        <div className="chat-messages">
          {Object.keys(convertedMessages).map(date => _renderMessageConts(date, convertedMessages[date]))}
        </div>
      </div>
      <div className="chat-at-conts-wrapper">
        {_renderChatAtConts()}
      </div>
      {_renderTextForm()}
    </div>
  )
}



const reloadPopOver = (chatId) => {
  $(`[data-parts="chat-stamp"][data-chat-id="${chatId}"]`).ggpopover('show')
}

const setPopOverEvent = () => {
  $('[data-parts="chat-stamp"]').ggpopover({ container: 'body', html: true, placement: 'bottom', trigger: 'hover' })
  $('[data-parts="chat-read"]').ggpopover({ container: 'body', html: true, placement: 'left', trigger: 'hover' })
  $('[data-parts="chat-msg-prog"]').ggpopover({ container: 'body', html: true, placement: 'top', trigger: 'click' })
  $('[data-parts="chat-delete"]').ggpopover({ container: 'body', html: true, placement: 'bottom', trigger: 'hover' })
}
