import { log } from '../util/logger'

interface Error {
  status: number
  message: string
}

const isError = (arg: any): arg is Error => {
  return arg !== null &&
  typeof arg === 'object' &&
  typeof arg.status === 'number' &&
  typeof arg.message === "string"
}


export const showAlert = (message: string) => {
  alert(message)
}

export const showErrorAlert = (error: Error | string) => {
  if (isError(error)) {
    // log(`${error.status}, ${error.message}`)
    alert(error.message)
  } else {
    alert(error)
  }
}

export const ignoreError = (error: Error) => {
  log(`${error.status}, ${error.message}`)
}
