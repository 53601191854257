
export const timeAgoInWords = (date: string) => {
  const now = new Date
  const created = new Date(date)
  const diff = now.getTime() - created.getTime()
  const second = Math.floor(diff / 1000)
  const minite = Math.floor(diff / (1000 * 60))
  const hour = Math.floor(diff / (1000 * 60 * 60))

  //現在時刻とcreatedの差が60秒未満の場合は「今」
  if (second < 60) return `今`
  //60分未満の場合は「n分前」
  if (second < 60 * 60) return `${minite}分前`
  //60分以上-24時時間未満の場合は「n時間前」
  if (second < 60 * 60 * 24) return `${hour}時間前`
  //あとは日付
  return formatDate(date)
}

// yyyy/mm/dd（weekDay）
export const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const weekDaysStr = _getWeekDayStr(date)
  return `${year}/${month}/${day} (${weekDaysStr})`
}

// yyyy/mm/dd（weekDay）hh:mm
export const formatDatetime = (dateString: string, short: boolean = false) => {
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const weekDaysStr = _getWeekDayStr(date)
  const hours = date.getHours()

  let minutes = date.getMinutes()
  let min = `${minutes}`
  if (minutes < 10) {
    min = `0${minutes}`
  }

  if (short) {
    return `${month}/${day}(${weekDaysStr}) ${hours}:${min}`
  }
  return `${year}/${month}/${day}(${weekDaysStr}) ${hours}:${min}`
}

const _getWeekDayStr = (date: Date) => {
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]
}