const { NODE_ENV } = process.env

export const endpoint = () => {
  const protocol = location.protocol
  if (NODE_ENV == 'development') {
    return protocol + '//localhost:3000/api'
  }
  return protocol + '//ganttchat.com/api'
}

export const pusherKey = () => {
  if (NODE_ENV == 'development') {
    return '4a21b242eae7ccb9a024'
  }
  return '54a1a76867fc87292101'
}