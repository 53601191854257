import { gantt } from 'dhtmlx-gantt'
import { closeChatWindow } from '../chat/chat'


// 権限のないプロジェクトは変更不可
export const isOwner = (task: any): boolean => {
  if (task.root) {
    if (task.is_owner == 0) return false
  } else {
    if (gantt.getTask(task.parent).is_owner == 0) return false
  }
  return true
}

// タスク追加時
// - プロジェクトを開いた状態にする
// - マイルストーンの描画位置を調整する
export const addTaskToGantt = (task: any) => {
  gantt.addTask(task)

  if (task.type != gantt.config.types.project) {
    gantt.open(task.parent)
  }

  if (task.type == gantt.config.types.milestone) {
    shiftMilestone(task.id)
  }
  updateDateRange()
}

export const deleteTaskFromGantt = (taskId: string) => {
  closeChatWindow()
  gantt.deleteTask(taskId)
}


// マイルストーンを中央に移動する
// https://docs.dhtmlx.com/gantt/desktop__milestones.html
// https://snippet.dhtmlx.com/5/340d1d85b

export const shiftAllMilestones = () => {
  gantt.eachTask(task => {
    shiftMilestone(task.id)
  })
}
  
export const shiftMilestone = (id: string) => {
  const task = gantt.getTask(id)
  if (task.type == gantt.config.types.milestone) {
    task.start_date = gantt.date.date_part(task.start_date)
    task.start_date = gantt.date.add(task.start_date, 12, 'hour')
    task.shifted = true // onAfterTaskUpdateで保存処理をスキップするため
    gantt.updateTask(id, undefined)
  }
}

// ガントチャートの開始/終了日時の設定
// - 本日から前後6ヶ月分の期間を表示
// - タスク追加時などにこの範囲を超える場合は再設定を実行する
export const updateDateRange = () => {
  const today = new Date()

  const day = 24 * 3600 * 1000
  const padding = 180 * day // 前後180日間多く表示

  let start_date = new Date(today.getTime() - padding)
  let end_date = new Date(today.getTime() + padding)
  let needRefresh = false

  gantt.eachTask(task => {
    if (task.type == gantt.config.types.project) {
      
      // タスクの前後10日の余裕をもたせる
      const task_start_at = task.start_date.getTime() - 10 * day
      const task_end_at = task.end_date.getTime() + 10 * day

      if (task_start_at < start_date.getTime()) {
        start_date = new Date(task_start_at)
        needRefresh = true
      }
      if (task_end_at > end_date.getTime()) {
        end_date = new Date(task_end_at)
        needRefresh = true
      }
    }
  })

  if (needRefresh || gantt.config.start_date === undefined || gantt.config.end_date === undefined) {
    gantt.config.start_date = start_date
    gantt.config.end_date = end_date
    gantt.render()
  }
}